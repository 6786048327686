export type BroadcasterCallback = (event: TolinkEvent)=>void

const CHANNEL = 'tolink::event'

export class TolinkBroadcaster{

    private broadcastChannel: BroadcastChannel

    public constructor(){
        this.broadcastChannel = new BroadcastChannel(CHANNEL)
    }

    addCallback(callback: BroadcasterCallback){
        const channel = new BroadcastChannel(CHANNEL);
        channel.onmessage = (e) => {
            callback(e.data)
        }
        return channel;
    }

    emit(event: TolinkEvent){
        this.broadcastChannel.postMessage(event)
    }
}

export interface TolinkEvent{
    event: string
    data?: any
}

export default new TolinkBroadcaster();